<template>
	<Modal
		:options="options"
		:popup="true"
		:btnStyleClass="'transparent-night'"
		:modal-class="'popup'"
		@close="emit('confirm')"
	>
		<div class="services__popup">
			<div
				v-if="modalContent.lead"
				class="m-b-24 c-white f-lead"
				v-thtml="modalContent.lead"
				style="white-space: pre-wrap"
			/>
			<div
				v-if="modalContent.text"
				class="m-b-16 c-white f-body"
				v-thtml="modalContent.text"
				style="white-space: pre-wrap"
			/>
			<Grid
				v-if="modalContent.list && modalContent.list.length"
				:grid-style="'serv'"
				:array="modalContent.list"
			>
				<template #gridItem="{ item }">
					<div
						class="services__info-list f-body c-white"
						v-thtml="item"
						style="white-space: pre-wrap"
					/>
				</template>
			</Grid>
		</div>
	</Modal>
</template>

<script setup lang="ts">
import Grid from '~/components/ui/Grid.vue';
import Modal from '~/components/ui/Modal.vue';
import type { IModalProps } from '~/types';

const options: IModalProps = {
	teleportTo: 'body',
	contentTransition: 'vfm-fade',
	overflowTransition: 'vfm-fade',
	escToClose: true,
	clickToClose: true,
	class: 'modal-wrap',
	contentClass: 'modal-content',
	overlayClass: 'modal-overlay',
};

defineProps({
	modalContent: { type: Object, default: {}, required: true, readonly: true }
});

const emit = defineEmits(['confirm']);
</script>

<style scoped lang="scss">

</style>
