<template>
    <div
        v-if="services.length"
        class="services"
        ref="serviceContainer"
    >
        <div
            class="services__links m-b-64"
            ref="scrolledLinks"
        >
            <Button
                v-for="service in services"
                :key="`service-link-${service.id}`"
                :btn-style="'tetrary-night'"
                :btn-size="'medium'"
                class="services__link"
                :class="activeAnchor === service.anchor ? 'active' : ''"
                @click="() => goToActiveBlock(service.anchor)"
            >
                {{ service.link }}
            </Button>
        </div>

        <div class="services__wrap">
            <div
                v-for="service in services"
                :key="`service-block-${service.id}`"
                :id="`services-block-${service.anchor}`"
                class="services__block p-t-145-lg p-t-145-dp p-t-40-tab p-t-64-mob p-b-145-lg p-b-145-dp p-b-145-tab p-b-64-mob"
                ref="scrolledBlocks"
                :data-scroll-block="service.anchor"
                itemscope
                itemtype="https://schema.org/Product"
                vocab="https://schema.org/"
                typeof="Product"
            >
                <div
                    v-if="viewport.isLessThan('desktopMedium')"
                    class="services__spline-wrap m-b-40"
                >
                    <Spline
                        class="services__spline"
                        :url="getSplineSrc(service.anchor)"
                        :type="'service'"
                    />
                </div>
                <h2
                    v-if="service.title"
                    class="m-b-40 f-h1 f-h1-5-mob c-gradient services__h1"
                    itemprop="name"
                    property="name"
                    v-thtml="service.title ? service.title : service.link"
                />
                <meta
                    itemprop="price"
                    property="price"
                    :content="service.price"
                />
                <meta
                    itemprop="priceCurrency"
                    property="priceCurrency"
                    content="RUB"
                />
                <meta
                    itemprop="image"
                    property="image"
                    :content="service.meta_img"
                />

                <div
                    v-if="service.description"
                    class="m-b-64 c-white-84 f-lead f-lead-2-mob"
                    itemprop="description"
                    property="description"
                    v-thtml="service.description"
                    style="white-space: pre-wrap"
                />

                <Grid
                    v-if="service.blocks"
                    class="m-b-32"
                    :grid-style="'serv'"
                    :array="service.blocks"
                >
                    <template #gridItem="{ item, index }">
                        <div
                            :class="[
                                'services__info b-t-black-20 p-t-32 p-b-32',
                                item.infoClass ? `services__info--${item.infoClass}` : ''
                            ]"
                            itemprop="offers"
                            itemscope
                            itemtype="https://schema.org/Offer"
                            property="offers"
                            typeof="Offer"
                        >
                            <NuxtImg
                                v-if="item.icon"
                                class="services__icon m-b-16"
                                :src="item.icon"
                                loading="lazy"
                                densities="x1 x2"
                                format="webp"
                                :alt="item.title"
                                :title="item.title"
                                placeholder
                            />
                            <h3
                                v-if="item.title"
                                class="m-b-16 f-h3"
                                itemprop="name"
                                property="name"
                            >
                                {{ item.title }}
                                <Button
                                    v-if="viewport.isLessThan('tablet')"
                                    class="services__info-btn"
                                    :btn-size="'small'"
                                    :icon-name="'info-button'"
                                    :modal="PopupService"
                                    :modalContent="item"
                                />
                            </h3>
                            <template v-if="!viewport.isLessThan('tablet')">
                                <div
                                    v-if="item.lead"
                                    class="m-b-24 c-white-84 f-lead"
                                    v-thtml="item.lead"
                                    style="white-space: pre-wrap"
                                />
                                <div
                                    v-if="item.text"
                                    class="m-b-16 c-white-60 f-body"
                                    v-thtml="item.text"
                                    style="white-space: pre-wrap"
                                />
                                <Grid
                                    v-if="item.list && item.list.length"
                                    :grid-style="'serv'"
                                    :array="item.list"
                                >
                                    <template #gridItem="{ item }">
                                        <div
                                            class="services__info-list f-body c-white-60"
                                            v-thtml="item"
                                            style="white-space: pre-wrap"
                                        />
                                    </template>
                                </Grid>
                            </template>
                        </div>
                    </template>
                </Grid>
                <div v-if="service.tags" class="services__info b-t-black-20 p-t-32">
                    <Grid :grid-style="'big-line'" :array="service.tags">
                        <template #gridItem="{ item }">
                            <Tag class="tag--night">
                                {{ item }}
                            </Tag>
                        </template>
                    </Grid>
                </div>
            </div>
        </div>

        <Transition name="fade">
            <div
                v-if="!viewport.isLessThan('desktopMedium')"
                class="services__spline-wrap"
            >
                <Spline
                    v-for="(service, i) in services"
                    :key="`service-canvas-${service.id}`"
                    :class="[
						'services__spline',
						activeAnchor === service.anchor ? 'active' : ''
	                ]"
                    :url="getSplineSrc(service.anchor)"
                    :type="'service'"
                />
            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
import { useJsonld } from '#jsonld';
import Button from '~/components/ui/Button.vue';
import Tag from '~/components/ui/Tag.vue';
import Grid from '~/components/ui/Grid.vue';
import { useCommonStore, useServicesStore, useSplinesStore } from '~/store';
import { useWindowScroll, watchDebounced } from '@vueuse/core';
import PopupService from '~/components/modals/PopupService.vue';
import { SCROLL_DEBOUNCE } from '~/utils/constants';

const serviceContainer = ref<HTMLElement | null>(null);
const activeAnchor = ref();
const scrolledBlocks = ref(null);
const scrolledLinks = ref();
const { fetchServices } = useServicesStore();
const { splines } = storeToRefs(useSplinesStore());
const { services } = storeToRefs(useServicesStore());
const { headerHeight } = storeToRefs(useCommonStore());
await useAsyncData(() => fetchServices());
const viewport = useViewport();
const { y } = useWindowScroll(window);

watchDebounced(y, () => {
    if (
        serviceContainer.value &&
        serviceContainer.value.getBoundingClientRect().top -
        screen.height / 4 <= 0 &&
        serviceContainer.value.getBoundingClientRect().top +
        serviceContainer.value.offsetHeight -
        screen.height / 2.5 >= 0
    ) {
        scrolledBlocks.value?.forEach((block: HTMLElement) => {
            let top = block.getBoundingClientRect().top;
            let bottom = block.getBoundingClientRect().top + block.offsetHeight;

            if (top <= 500 && bottom >= 500) {
                activeAnchor.value = block.getAttribute(`data-scroll-block`);

                /* БАГ на мобилке при тач, проверить!! */
                if (viewport.isLessThan('tablet')) {
                    const computedStyle = getComputedStyle(scrolledLinks.value);
                    const activeLink =
                        scrolledLinks.value.querySelector('.active');

                    if (activeLink) {
	                    scrolledLinks.value.scrollTo({
                            top: 0,
                            left:
                                activeLink.offsetLeft -
                                parseFloat(computedStyle.paddingLeft),
                            behavior: `smooth`,
                        });

	                    // scrolledLinks.value.scrollLeft = activeLink.offsetLeft -
			            //         parseFloat(computedStyle.paddingLeft);
                    }
                }
            }
        });
    } else {
        activeAnchor.value = '';
    }
}, { debounce: SCROLL_DEBOUNCE, maxWait: SCROLL_DEBOUNCE * 2 });

const Spline = defineAsyncComponent(() => import('~/components/ui/Spline.vue'));
function getSplineSrc(anchor: string) {
    const spline = splines.value.filter(el => el.type === "service").find(el => el.anchor === anchor);
	if (spline) {
		return spline.src ? spline.src : spline.spline_path
	} else {
		return '';
	}
}

onMounted(() => {
    if (services.value.length) {
        activeAnchor.value = services.value[0].anchor;
    }
});

onUnmounted(() => {
    activeAnchor.value = '';
});

const goToActiveBlock = (anchor: string) => {
	if (!scrolledBlocks.value) return false;
    const prevActiveBlock = scrolledBlocks.value.find(
        (el: HTMLElement) => el.getAttribute(`data-scroll-block`) === activeAnchor.value
    );
    activeAnchor.value = anchor;
    const activeBlock = scrolledBlocks.value.find(
        (el: HTMLElement) => el.getAttribute(`data-scroll-block`) === anchor
    );

    const prevIndex = [].indexOf.call(scrolledBlocks.value, prevActiveBlock);
    const index = [].indexOf.call(scrolledBlocks.value, activeBlock);

    scrolledBlocks.value.forEach((block: HTMLElement) => {
        block.classList.remove(`active-from`);
        block.classList.remove(`active-to`);
    });

    if (process.client) {
		if (viewport.isLessThan(`desktopWide`)) {
			window.scrollTo({
				top:
					activeBlock.getBoundingClientRect().top +
					window.scrollY -
					headerHeight.value,
				left: 0,
			});
		} else {
			activeBlock.scrollIntoView();
		}

        if (prevIndex > index) {
            activeBlock.classList.add(`active-from`);
        } else {
            activeBlock.classList.add(`active-to`);
        }
    }
};

// @ts-ignore
useJsonld(() => {
    if (services.value.length) {
        // const arr: { [key: string]: string; }[] = [];
        // servicesStore.services.forEach((el, index: number) => {
        //     arr.push({
        //         '@type': 'ListItem',
        //         'position': `${index}`,
        //         'name': el.name,
        //
        //     });
        // });
        // return {
        //     '@context': 'https://schema.org',
        //     '@type': 'Product',
        //     'itemListElement': arr,
        // };
    }
});
</script>

<style
    lang="scss"
    src="~/assets/scss/components/index/services.scss"
    scoped
></style>
