import { IS_DEV } from '~/utils/constants';
import { defineStore } from 'pinia';
import type { IService, IServicesResponse } from '~/types';

export const useServicesStore = defineStore('services', {
    state: () => ({
        totalCount: 0 as number,
        services: {} as IService[],
        mockServices: [
            {
                id: '0',
                anchor: 'analytic',
                link: 'Аналитика',
                title: 'Аналитика и проектирование',
                description:
                    'Проект начинается с понимания проблематики, требований пользователей и и представления каким образом мы будет решать поставленные задачи.Мы даем понимание, что мы делаем и почему, а главное какими инструментами.',
                price: '',
                meta_img: '',
                blocks: [
                    {
                        title: 'Сбор данных и аналитика',
                        icon: '/images/services/service-1.svg',
                        text: 'Анализируем конкурентов и лучших решений на рынке, формируем гипотезы и предложения.Проводим сбор данных, для выявления проблем, с которыми сталкиваются клиенты.',
                        gridClass: '',
                        lead: '',
                        list: [],
                        infoClass: '',
                    },
                    {
                        title: 'Интерактивные прототипы',
                        icon: '/images/services/service-2.svg',
                        text: 'Разрабатываем UX/UI решения для мобильных и десктопных платформ, визуализируем сценарии взаимодействия пользователя с проектом.',
                        gridClass: '',
                        lead: '',
                        list: [],
                        infoClass: '',
                    },
                    {
                        title: 'Проектирование',
                        icon: '/images/services/service-3.svg',
                        text: 'Продумываем и расписываем схемы взаимодействия систем проекта, пишем технические задания для фиксации требований, технологий и API проекта.Исключаем недопонимания в процессе работы над проектом.',
                        gridClass: '',
                        lead: '',
                        list: [],
                        infoClass: '',
                    },
                ],
                tags: [
                    'CJM',
                    'Анализ персон',
                    'Брифинг и интервью',
                    'Фокус группы',
                    'Скетчинг',
                ],
            },
            {
                id: '1',
                anchor: 'design',
                link: 'Дизайн',
                title: 'Дизайн и брендинг',
                description:
                    'Обладаем большим набором компетенций в создании впечатляющих визуальных решений и дизайн систем. Наш подход обеспечивает эффективный дизайн, который воплощает наш богатый опыт. Применяем нашу экспертизу для реализации ваших проектов и достижения поставленных целей',
                price: '',
                meta_img: '',
                blocks: [
                    {
                        title: 'Дизайн',
                        icon: '/images/services/service-1.svg',
                        text: 'Создаем уникальный дизайн и интерфейсы для настольных и мобильных устройств. Внимательно продумываем интерфейс, адаптируя его под требования аудитории, и смело воплощаем креативные идеи. Расставляем визуальные акценты, привлекаем и удерживаем внимание целевой аудитории, делая проекты успешными',
                        gridClass: '',
                        lead: '',
                        list: [],
                        infoClass: '',
                    },
                    {
                        title: 'Брендинг и айдентинка',
                        icon: '/images/services/service-2.svg',
                        text: 'Разрабатываем UX/UI решения для мобильных и десктопных платформ, визуализируем сценарии взаимодействия пользователя с проектом.',
                        gridClass: '',
                        lead: '',
                        list: [],
                        infoClass: '',
                    },
                    {
                        title: 'Передавая no-code разработка на Tilda',
                        icon: '/images/services/service-3.svg',
                        text: 'Создаем впечатляющие сайты и лендинги без сложного функционала. Гармоничное взаимодействие дизайна, анимаций и контента для привлечения аудитории и впечатляющего пользовательского опыта',
                        gridClass: '',
                        lead: '',
                        list: [],
                        infoClass: '',
                    },
                ],
                tags: [
                    'Моушн дизайн',
                    'High-fidelity макеты (дизайн прототипы)',
                    'Разработка иконок и иллюстраций',
                    'Презентации',
                    'Дизайн системы',
                    'Дизайн поддержка',
                    'Брендинг',
                    'Бренд буки',
                ],
            },
            {
                id: '2',
                anchor: 'development',
                link: 'Разработка',
                title: 'Разработка и внедрение',
                description: '',
                price: '',
                meta_img: '',
                blocks: [
                    {
                        title: 'Магазины на 1С-Битрикс',
                        icon: '/images/services/service-1.svg',
                        text: '',
                        gridClass: 'full',
                        lead: 'Разрабатываем уникальные интернет-магазины на 1С-Битрикс, создаем решения, которые помогают бизнесу достичь поставленных целей.',
                        list: [
                            'Осуществляем полную интеграцию с сервисами, складским учетом, системами доставки и платежными системами. Разрабатываем собственные модули и решаем не тривиальные задачи.',
                            'Работаем с ключевыми показателями и внедряем решения, которые помогут увеличить средний чек, снизить количество брошенных корзин и неоплаченных заказов. Обеспечиваем быстрый и релевантный поиск.',
                        ],
                        infoClass: '',
                    },
                    {
                        title: 'Веб-сервисы и личные кабинеты',
                        icon: '/images/services/service-2.svg',
                        text: '',
                        gridClass: 'full',
                        lead: 'Разрабатываем B2B и B2C решения, личные кабинеты и внутренние системы компаний. Создаем продуманную архитектуру, удобные интерфейсы.',
                        list: [
                            'Цифровизируем бизнес-процессы: увеличиваем производительность, снижаем затраты, улучшаем доступ к информации, даем новые возможности бизнесу и улучшаем клиентский опыт.',
                            'Интегрируем проекты с другими системами, обмениваясь пользователями, обращениями, заказами и другими важными данными.',
                        ],
                        infoClass: '',
                    },
                    {
                        title: 'Мобильные приложения',
                        icon: '/images/services/service-3.svg',
                        text: '',
                        gridClass: 'full',
                        lead: 'Разрабатываем кроссплатформенные мобильные приложений на Flutter. Специализируемся на проработке интерфейсов, скорости работы и удобстве использования.',
                        list: [
                            'Используем данные вашего магазина или веб-сервиса для централизованного хранения и работы с данными.',
                            'Расширяем маркетинговый и информационный потенциал бизнеса, предоставляем удобный доступ к продуктам и услугам, увеличиваем вовлеченность пользователей.',
                        ],
                        infoClass: '',
                    },
                    {
                        title: 'Комплексные решения',
                        icon: '/images/services/service-1.svg',
                        text: '',
                        gridClass: 'full',
                        infoClass: 'bg',
                        lead: 'Объединяем данные из ваших магазинов, сервисов, приложений. Визуализируем и даем контроль над ними. Наш подход позволяет достичь синергии между всеми ИТ-платформами вашего бизнеса.',
                        list: [
                            'Внедряем CRM Битрикс24 для эффективного управления клиентской базой, автоматизаций продаж. Цифровизируем бизнес процессы компании, разрабатываем цепочки согласований и контроля документов. Улучшаем контроль, оптимизируем операционные процессы и повышаем эффективность работы команды.',
                            'Разработаем информационные дашборды в Yandex DataLens. Отслеживайте ключевые показатели эффективности, анализируйте тенденции и принимайте важные стратегические решения.',
                        ],
                    },
                ],
                tags: [],
            },
            {
                id: '3',
                anchor: 'progress',
                link: 'Развитие',
                title: 'Развитие и продвижение',
                description:
                    'Наша команда берет на себя задачи модернизации и сопровождения проектов. Специализируемся на решении задач бизнеса и достижении долгосрочных целей.Анализируем ваши бизнес-задачи, создаем прогрессивную дорожную карту работ на период от 3 до 12 месяцев.',
                price: '',
                meta_img: '',
                blocks: [
                    {
                        title: 'SEO и SERM',
                        icon: '/images/services/service-1.svg',
                        text: 'Работаем с репутацией и выводим сайты в ТОП поисковой выдачи Яндекс и Google.Наша стратегия направлена на увеличение посещаемости, звонков и заявок.',
                        gridClass: '',
                        lead: '',
                        list: [],
                        infoClass: '',
                    },
                    {
                        title: 'Таргет',
                        icon: '/images/services/service-2.svg',
                        text: 'Комплексная настройка и ведение контекстной рекламы и таргета вЯндекс.Директ, VK и других платформах',
                        gridClass: '',
                        lead: '',
                        list: [],
                        infoClass: '',
                    },
                ],
                tags: [
                    'Service Desk',
                    'Мониторинг',
                    'Планирование',
                    'A/B тестирование',
                    'Отчеты',
                ],
            },
        ] as IService[],
        service: {} as IService,
        isLoading: false as boolean,
    }),
    actions: {
        setServices(services: IService[]) {
            this.services = services;
        },

        setService(service: IService) {
            this.service = service;
        },

        // fetch all services
        async fetchServices(): Promise<IService[] | null> {
            if (IS_DEV) {
                this.setServices(this.mockServices);
                this.totalCount = this.mockServices.length;
                return this.mockServices;
            } else {
                this.isLoading = true;

                try {
                    const data: IServicesResponse = await $fetch(
                        `/api/services`
                    );

                    if (data) {
                        this.totalCount = data.total_count;
                        this.setServices(data.result);
                        if (data.result) {
                            return data.result;
                        }

                        return null;
                    }

                    return null;
                } catch (error) {
                    console.error('ERROR on fetchServices: ', error);
                    return null;
                } finally {
                    this.isLoading = false;
                }
            }
        },

        // fetch service by id
        async findService(id: number | string): Promise<IService | null> {
            if (IS_DEV) {
                this.setService(this.mockServices[0]);
                return this.mockServices[0];
            } else {
                this.isLoading = true;

                try {
                    const data: IService = await $fetch(
                        `/api/services/?id=${id}`
                    );

                    if (data) {
                        this.setService(data);
                        return data;
                    }

                    return null;
                } catch (error) {
                    console.error('ERROR on findService: ', error);
                    return null;
                } finally {
                    this.isLoading = false;
                }
            }
        },
    },
});
