<template>
    <main class="main">
        <section
            class="section section--top m-b-16-lg m-b-24-dp m-b-64-tab m-b-64-mob"
        >
            <div class="container container--full" data-aos="fade-up" data-aos-delay="200">
                <BannerMain />
            </div>
        </section>

        <section
            class="section section--position p-b-24-lg p-b-24-dp p-b-64-tab p-b-64-mob"
            data-aos="fade"
        >
            <div class="container container--full">
                <Cases />
            </div>
        </section>

        <NightMode
	        id="services-page"
            class="section night-mode--spline"
        >
            <div
                class="container container--vw-dp-min"
                data-aos="fade"
            >
                <Services />
            </div>

            <SplineFeedbackForm />
        </NightMode>
    </main>
</template>

<script setup lang="ts">
import BannerMain from '~/components/index/BannerMain.vue';
import Cases from '~/components/index/Cases.vue';
import NightMode from '~/components/ui/NightMode.vue';
import Services from '~/components/index/Services.vue';
import SplineFeedbackForm from '~/components/forms/SplineFeedbackForm.vue';
import { useCommonStore, useCasesStore } from '~/store';
import { useModal } from 'vue-final-modal';
import ModalVideo from '~/components/modals/ModalVideo.vue';

const { commonInfo } = storeToRefs(useCommonStore());
const { isLoading } = storeToRefs(useCasesStore());
const route = useRoute();

defineOptions({ name: 'MainPage' });

const casesLoading = computed(() => {
	return isLoading.value;
});

watch(casesLoading, () => {
	nextTick(() => {
		if (!casesLoading.value && route.hash && process.client) {
			document.querySelector(route.hash)?.scrollIntoView();
		}
	});
});

function openShowreelModal(autoplay: boolean = true) {
    const { open, close } = useModal({
        component: ModalVideo,
        attrs: {
            videoSrc: commonInfo.value.showreelUrl,
            autoplay: autoplay,
            onConfirm(): void {
	            history.replaceState(null, '', ' ');
	            close();
            },
        },
    });

    open();
}
function onHashChanged() {
    if (location.hash === '#showreel') {
        openShowreelModal();
    }
}

onMounted(() => {
    if (process.client) {
        window.addEventListener('hashchange', onHashChanged);

        if (location.hash === '#showreel') {
            openShowreelModal(false);
        }
    }
});

onUnmounted(() => {
    if (process.client) {
        window.removeEventListener('hashchange', onHashChanged);
    }
});
</script>

<style lang="scss" src="~/assets/scss/pages/section.scss" scoped></style>
