<template>
    <h2 class="m-b-24-lg m-b-24-dp m-b-40-tab m-b-40-mob p-l-32-lg p-l-32-dp p-l-20-tab f-h1 f-h2-5-tab f-h1-5-mob">
	    Кейсы
    </h2>

    <template v-if="viewport.isLessThan('desktopMedium')">
        <SliderCases
	        class="m-b-40"
	        :page-limit="CASES_MAIN_PAGE_LIMIT"
	        :pop="'Y'"
        />
        <Button
            class="w-100"
            :btn-size="'medium'"
            :icon-name="'stroke-down'"
            @click="$router.push({ name: 'cases' })"
        >
            Смотреть все
        </Button>
    </template>
    <template v-else>
        <CaseGrid
	        :page-limit="CASES_MAIN_PAGE_LIMIT"
	        :case-more="true"
	        :pop="'Y'"
        />
    </template>
</template>

<script setup lang="ts">
import Button from '~/components/ui/Button.vue';
import SliderCases from '~/components/sliders/SliderCases.vue';
import CaseGrid from '~/components/cases/CaseGrid.vue';
import { CASES_MAIN_PAGE_LIMIT } from '~/utils/constants';

const viewport = useViewport();
</script>

<style lang="scss" src="~/assets/scss/pages/section.scss" scoped></style>
