<template>
    <div class="case-card case-card--loader">
        <div class="case-card__loader"></div>
    </div>
</template>

<script setup lang="ts"></script>

<style
    lang="scss"
    src="~/assets/scss/components/cases/case-card.scss"
    scoped
></style>
