<template>
	<div class="banner-main">
		<div class="banner-main__wrap m-b-12-tab m-b-40-mob">
			<div class="banner-main__left">
				<h1 class="banner-main__title f-big-lg f-h1-dp f-h1-5-tab f-h1-5-mob">
					<span class="banner-main__title-animation"></span>
					{{ string }}
				</h1>
			</div>
			<div class="banner-main__right">
				<h2
					class="f-h2-lg f-h3-dp f-h3-tab f-h3-mob m-b-24-lg m-b-16-dp m-b-16-tab m-b-16-mob"
					data-aos="fade"
					data-aos-delay="500"
				>
					Смотреть <br />Showreel
				</h2>

				<Button
					class="banner-main__btn m-t-40-tab m-t-40-mob"
					:btn-style="'showreel-border'"
					:modal="ModalVideo"
                    :is-showreel="true"
					:videoSrc="commonInfo.showreelUrl"
					aria-label="Открыть шоурил"
				/>
				<div
                    v-if="commonInfo.showreelPreview"
					class="banner-main__btn-wrap"
				>
					<Video
						class="video--big-btn"
						:src="commonInfo.showreelPreview"
						:playsinline="true"
						:autoplay="true"
						:muted="true"
						:loop="true"
					/>
				</div>
			</div>
		</div>
		<div
            class="banner-main__bottom"
            data-aos="fade"
            data-aos-delay="700"
		>
			<h3 class="c-black-60 m-b-24 f-h3">Клиенты</h3>
			<SliderBrands />
		</div>

		<Transition name="fade">
			<Spline
				v-if="spline"
				data-aos="fade"
				data-aos-delay="800"
				data-aos-duration="1000"
				:url="spline.src ? spline.src : spline.spline_path"
                :url-mobile="spline.src_mobile"
				class="spline--banner"
			/>
		</Transition>
	</div>
</template>

<script setup lang="ts">
import SliderBrands from '~/components/index/SliderBrands.vue';
import Button from '~/components/ui/Button.vue';
import Video from '~/components/ui/Video.vue';
import ModalVideo from '~/components/modals/ModalVideo.vue';
import { useCommonStore, useSplinesStore } from '~/store';

const { commonInfo } = storeToRefs(useCommonStore());
const splinesStore = useSplinesStore();
const string: string = 'Создаем digital решения для продаж и процессов бизнеса';
const Spline = defineAsyncComponent(() => import('~/components/ui/Spline.vue'));

const spline = computed(() => {
	return splinesStore.splines.find((el) => el.type === 'banner');
});
</script>

<style
    lang="scss"
    src="~/assets/scss/components/index/banner-main.scss"
    scoped
></style>
