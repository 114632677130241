import { IS_DEV } from '~/utils/constants';
import { defineStore } from 'pinia';
import type { IBrand, IBrandsResponse } from '~/types';

export const useBrandsStore = defineStore('brands', {
    state: () => ({
        brands: [] as IBrand[],
        mockBrands: [
            { id: '0', name: 'Газпром', src: '/images/brands/brand-1.png', alt: '', title: '', height: '124', width: '252' },
            { id: '1', name: 'Marvel', src: '/images/brands/brand-2.png', alt: '', title: '', height: '124', width: '298' },
            { id: '2', name: 'F-plus', src: '/images/brands/brand-3.png', alt: '', title: '', height: '124', width: '242' },
            { id: '3', name: 'РТЛабс', src: '/images/brands/brand-4.png', alt: '', title: '', height: '124', width: '150' },
            { id: '4', name: 'Omega', src: '/images/brands/brand-5.png', alt: '', title: '', height: '62', width: '268' },
            { id: '5', name: 'Авангард', src: '/images/brands/brand-6.png', alt: '', title: '', height: '62', width: '237' },
            { id: '6', name: 'Royal Wedding', src: '/images/brands/brand-7.png', alt: '', title: '', height: '124', width: '122' },
            { id: '7', name: 'Anna Verdi', src: '/images/brands/brand-8.png', alt: '', title: '', height: '62', width: '250' },
            { id: '8', name: '1001 туфелька', src: '/images/brands/brand-9.png', alt: '', title: '', height: '124', width: '135' },
        ] as IBrand[],
        brand: {} as IBrand,
        isLoading: false as boolean,
        totalCount: 0 as number,
    }),
    actions: {
        setBrands(brands: IBrand[]) {
            this.brands = brands;
        },

        setBrand(brand: IBrand) {
            this.brand = brand;
        },

        // fetch all brands
        async fetchBrands(): Promise<IBrand[] | null> {
            if (IS_DEV) {
                this.setBrands(this.mockBrands);
                this.totalCount = this.mockBrands.length;
                return this.mockBrands;
            } else {
                this.isLoading = true;

                try {
                    const data: IBrandsResponse = await $fetch(
                        `/api/brands`
                    );

                    if (data) {
                        this.totalCount = data.total_count ? data.total_count : data.result.length;
                        this.setBrands(data.result);
                        if (data.result) {
                            return data.result;
                        }

                        return null;
                    }

                    return null;
                } catch (error) {
                    console.error('ERROR on fetchBrands: ', error);
                    return null;
                } finally {
                    this.isLoading = false;
                }
            }
        },

        // fetch brand by id
        async findBrand(id: number | string): Promise<IBrand | null> {
            if (IS_DEV) {
                this.setBrand(this.mockBrands[0]);
                return this.mockBrands[0];
            } else {
                this.isLoading = true;

                try {
                    const data: IBrand = await $fetch(
                        `/api/brands/${id}`
                    );

                    if (data) {
                        this.setBrand(data);
                        return data;
                    }

                    return null;
                } catch (error) {
                    console.error('ERROR on findBrand: ', error);
                    return null;
                } finally {
                    this.isLoading = false;
                }
            }
        },
    },
});
