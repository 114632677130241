<template>
    <div class="slider-cases">
        <Slider
            class="swiper--cases"
            :sliderProps="sliderProps"
            :array="!isLoading ? casesStore.cases : [0, 1, 2, 3]"
        >
            <template #slide="{ slide }">
                <CaseCard v-if="!isLoading" :caseCard="slide" />
                <CaseLoader v-else />
            </template>
        </Slider>
    </div>
    <!-- прелоадер -->
</template>

<script setup lang="ts">
import Slider from '~/components/ui/Slider.vue';
import CaseCard from '~/components/cases/CaseCard.vue';
import CaseLoader from '~/components/cases/CaseLoader.vue';
import { useCasesStore } from '~/store';
import type { ISliderProps } from '~/types';

const props = defineProps({
    pageLimit: { type: Number, required: false, default: 3, readonly: true },
	pop: { type: String || Boolean, default: '', required: false, readonly: true }
});

const casesStore = useCasesStore();
const page = ref(1);
const isLoading = ref<boolean>(casesStore.isLoading);
const { data, pending, error, refresh } = await useAsyncData(
    () => casesStore.fetchCases(page.value, props.pageLimit, props.pop)
);

const sliderProps: ISliderProps = {
    slidesPerView: 'auto',
    spaceBetween: 12,
    pagination: {
        type: 'bullets',
        clickable: true,
    },
};
</script>
