<template>
    <div v-if="!isBrandsLoading" class="slider-brands">
        <div class="slider-brands__container">
            <div class="slider-brands__wrap">
                <div
                    v-for="slide in brands"
                    class="slider-brands__img"
                >
                    <NuxtImg
                        :src="slide.src"
                        :alt="slide.alt"
                        :title="slide.title"
                        :width="slide.width"
                        :height="slide.height"
                        loading="lazy"
                        densities="x1 x2"
                        format="webp"
                        placeholder
                    />
                </div>
            </div>
            <div aria-hidden="true" class="slider-brands__wrap">
                <div
                    v-for="slide in brands"
                    class="slider-brands__img"
                >
                    <NuxtImg
                        :src="slide.src"
                        :alt="slide.alt"
                        :title="slide.title"
                        :width="slide.width"
                        :height="slide.height"
                        loading="lazy"
                        densities="x1 x2"
                        format="webp"
                        placeholder
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useBrandsStore } from '~/store';
const { fetchBrands } = useBrandsStore();
const { brands, isLoading } = storeToRefs(useBrandsStore());
const isBrandsLoading = ref<boolean>(isLoading.value);

await useAsyncData(() => fetchBrands());
</script>

<style
    lang="scss"
    src="assets/scss/components/sliders/slider-brands.scss"
    scoped
></style>
